<template>
  <div class="row">
    <div class="col-12">
      <h4 class="section-heading">Create New Camera</h4>
      <div class="card widget widget-list">
        <div class="card-body" style="overflow-x: hidden">
          <form class="row g-3">
            <div class="col-12">
              <label for="inputModel" class="form-label">Model</label>
              <select id="inputModel" class="form-select">
                <option selected>Choose...</option>
                <option>...</option>
              </select>
            </div>
            <div class="col-12">
              <button type="submit" class="btn btn-primary">Create New</button>
            </div>
            <div class="col-12">
              <label for="inputMode" class="form-label">Mode</label>
              <select id="inputMode" class="form-select">
                <option selected>Choose...</option>
                <option>...</option>
              </select>
            </div>
            <div class="col-12">
              <button type="submit" class="btn btn-primary">Create New</button>
            </div>
            <div class="col-md-6">
              <label for="inputName" class="form-label">Name</label>
              <input
                type="text"
                class="form-control"
                id="inputName"
                placeholder="Camera Name"
              />
            </div>
            <div class="col-md-6">
              <label for="inputIp" class="form-label">IP</label>
              <input
                type="text"
                class="form-control"
                id="inputIp"
                placeholder="IP Address"
              />
            </div>
            <div class="col-12">
              <label for="inputMacAddress" class="form-label"
                >MAC Address</label
              >
              <input
                type="text"
                class="form-control"
                id="inputMacAddress"
                placeholder="MAC Address"
              />
            </div>
            <div class="col-12">
              <label for="inputSerialNumber" class="form-label"
                >Serial Number</label
              >
              <input
                type="text"
                class="form-control"
                id="inputSerialNumber"
                placeholder="Serial Number"
              />
            </div>
            <div class="col-12">
              <label for="inputApiKey" class="form-label">API Key</label>
              <input
                type="text"
                class="form-control"
                id="inputApiKey"
                placeholder="API Key"
              />
            </div>
            <div class="col-md-6">
              <label for="inputCameraUsername" class="form-label"
                >Camera Username</label
              >
              <input
                type="text"
                class="form-control"
                id="inputCameraUsername"
              />
            </div>
            <div class="col-md-6">
              <label for="inputCameraPassword" class="form-label"
                >Camera Password</label
              >
              <input
                type="text"
                class="form-control"
                id="inputCameraPassword"
              />
            </div>
            <div class="col-12">
              <button type="submit" class="btn btn-primary">
                Finish and Create
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Create",
};
</script>
